import Vue from "vue";
import vClickOutside from "v-click-outside";
import router from "./router";
import store from "./store";
import App from "./App";
import { generateSessionId, generateUUId } from "@/utils/userId";

import { BootstrapVue } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import "@/modules/validation";
import "@/modules/mediaDevicePolyfill";
import "@/assets/styles/index.scss";
import "./registerServiceWorker";

generateSessionId();
generateUUId();

Vue.use(vClickOutside);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== "production";

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
