<template>
  <div class="page">
    <loader v-if="loading" />
    <router-view />
    <popup-list :popups="popups" />
    <pwa-tooltip />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PopupList from "@/components/popups/_popupList";
import Loader from "@/components/loader";
import PwaTooltip from "@/components/pwaTooltip";
import { saveLog } from "@/modules/logger";
import { STORE_SHOW_POPUP, STORE_CLOSE_POPUP } from "@/constants";

export default {
  name: "Application",

  components: {
    Loader,
    PopupList,
    PwaTooltip,
  },

  provide() {
    return {
      showModal: this.showModal,
      closeModal: this.closeModal,
    };
  },

  computed: {
    ...mapState(["popups"]),

    loading() {
      return this.$store.state.loading;
    },
  },

  beforeDestroy() {
    saveLog("exit-page");
  },

  methods: {
    ...mapActions([STORE_SHOW_POPUP, STORE_CLOSE_POPUP]),

    showModal(type, options = true) {
      this[STORE_SHOW_POPUP]({ type, options });
    },

    closeModal(type) {
      this[STORE_CLOSE_POPUP](type);
    },
  },
};
</script>
