<template>
  <div>
    <modal
      v-for="popup in popupList"
      :key="popup.type"
      :style="{ zIndex: popup.zIndex }"
      :hideCloseButton="popup.options.hideCloseButton"
      @close="onClose(popup)"
    >
      <component :is="popup.type" @close="onClose(popup)" :options="popup.options" />
    </modal>
  </div>
</template>

<script>
import Modal from "./_popupWrapper";

import {
  POPUP_UPLOADING,
  POPUP_CONTACT_US,
  POPUP_ABOUT_US,
  POPUP_TERMS,
  POPUP_PRIVACY,
  POPUP_CHANGE_PASSWORD,
  POPUP_CHANGE_EMAIL,
  POPUP_CONFIRMATION,
  POPUP_NOTIFICATION,
  POPUP_LOST_STREAM,
  POPUP_SUPER_USER,
  POPUP_WELCOME,
  POPUP_THANK_YOU,
  POPUP_DETECTED_NOISE,
  POPUP_DETECTED_LOW_EFFORT,
  POPUP_CHANGE_REMINDERS,
  POPUP_NO_COUGH_DETECTED,
  POPUP_NO_VOICE_DETECTED,
  POPUP_NO_FE_DETECTED,
  POPUP_INSUFFICIENT_FE_DETECTED,
  POPUP_SESSION_NOISE_ERROR,
  POPUP_SESSION_RECORDING_ERROR,
} from "@/constants";

export default {
  components: {
    Modal,
    [POPUP_PRIVACY]: () => import("./privacy"),
    [POPUP_TERMS]: () => import("./terms"),
    [POPUP_ABOUT_US]: () => import("./aboutUs"),
    [POPUP_CONTACT_US]: () => import("./contactUs"),
    [POPUP_UPLOADING]: () => import("./uploading"),
    [POPUP_CHANGE_PASSWORD]: () => import("./changePassword"),
    [POPUP_CHANGE_EMAIL]: () => import("./changeEmail"),
    [POPUP_CHANGE_REMINDERS]: () => import("./changeReminders"),
    [POPUP_CONFIRMATION]: () => import("./confirmation"),
    [POPUP_NOTIFICATION]: () => import("./notification"),
    [POPUP_LOST_STREAM]: () => import("./lostStream"),
    [POPUP_SUPER_USER]: () => import("./superUserSignIn"),
    [POPUP_WELCOME]: () => import("./welcome"),
    [POPUP_THANK_YOU]: () => import("./thankYou"),
    [POPUP_DETECTED_NOISE]: () => import("./noiseDetected"),
    [POPUP_DETECTED_LOW_EFFORT]: () => import("./lowEffortDetected"),
    [POPUP_NO_COUGH_DETECTED]: () => import("./noCoughDetected"),
    [POPUP_NO_VOICE_DETECTED]: () => import("./noVoiceDetected"),
    [POPUP_NO_FE_DETECTED]: () => import("./noFEDetected"),
    [POPUP_INSUFFICIENT_FE_DETECTED]: () => import("./insufficientFEDetected"),
    [POPUP_SESSION_NOISE_ERROR]: () => import("./sessionNoiseError"),
    [POPUP_SESSION_RECORDING_ERROR]: () => import("./sessionRecordingError"),
  },

  inject: {
    closeModal: { default: function() {} }
  },

  props: {
    popups: { type: Object, default: () => ({}) }
  },

  computed: {
    popupList() {
      return Object.entries(this.popups).reduce(
        (acc, [type, options], index) => {
          return [
            ...acc,
            {
              type,
              options: typeof options === "boolean" ? {} : options,
              zIndex: 100 + index
            }
          ];
        },
        []
      );
    }
  },

  watch: {
    popupList(arr) {
      if (!arr.length) return;
      // needs to fix adding scrollbar after closing one of popups
      this.$nextTick(() => document.documentElement.classList.add("out"));
    }
  },

  methods: {
    onClose({ type, options }) {
      if (options?.force) return;
      if (options?.handleClose) {
        options.handleClose();
      }
      this.closeModal(type);
    }
  }
};
</script>
