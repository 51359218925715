import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import differenceInYears from 'date-fns/differenceInYears';
import { numToTimeFormat } from "@/utils/date";
import {
  UI_PHONE_MASK,
  MSG_ERROR_FUTURE_DATE,
  MSG_ERROR_INVALID_DAY,
  MSG_ERROR_TOO_YOUNG_AGE,
  MSG_ERROR_TOO_OLD_AGE,
  MIN_USER_HEIGHT,
  MAX_USER_HEIGHT,
  INCHE_VALUE,
  FEET_VALUE,
} from "@/constants";

import {
  required,
  required_if,
  email,
  min_value,
  max_value,
  numeric,
  min,
  max,
  is,
  is_not,
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Field {_field_} is required",
});

extend("numeric", numeric);
extend("required_if", required_if);
extend("email", email);
extend("min_value", min_value);
extend("max_value", max_value);
extend("min", min);
extend("max", max);
extend("is", is);
extend("is_not", is_not);
extend("float", (value) => {
  if (!/^\d+(\.\d+)?$/.test(value)) {
    return "{_field_} is not valid.";
  }

  return true;
});

extend("phone", {
  validate: (value) => ({
    valid:
      Array.from(value).every((char, i) => {
        const mask = UI_PHONE_MASK[i];
        if (typeof mask === "string") {
          return char === mask;
        }

        if (mask instanceof RegExp) {
          return mask.test(char);
        }

        return false;
      }) && value.length === UI_PHONE_MASK.length,
  }),
  message: "Invalid phone number",
});

extend("date", ({ year, month, day }) => {
  if (!year || !month || !day) return "Invalid date";

  const dayCount = new Date(year, month, 0).getDate();
  const isDayInvalid = dayCount < day;

  if (isDayInvalid) return MSG_ERROR_INVALID_DAY;

  const _day = numToTimeFormat(day);
  const _month = numToTimeFormat(month);
  const date = Date.parse(`${year}-${_month}-${_day}`);
  const current = Date.now();
  const isDateGreater = date > current;

  if (isDateGreater) return MSG_ERROR_FUTURE_DATE;

  return true;
});

extend("adult", ({ year, month, day = 1 }) => {
  const _day = numToTimeFormat(day);
  const _month = numToTimeFormat(month);
  const date = Date.parse(`${year}-${_month}-${_day}`);
  // const current = Date.now();
  var now = new Date();
  var current = new Date(now.getFullYear(), now.getMonth() + 1, 0)  // last day of this month
  const isUserNotAdult = differenceInYears(current, date)  < 18;
  const isUserTooOld = differenceInYears(current, date)  >= 95;

  console.log("validating age")
  console.log(differenceInYears(current, date))


  if (isUserNotAdult) return MSG_ERROR_TOO_YOUNG_AGE;
  if (isUserTooOld) return MSG_ERROR_TOO_OLD_AGE;
  return true;
});


extend("user_height", ({ height: h, units }) => {
  const height = +h;

  if (h === "" || h === null || Number.isNaN(height)) return "";

  if (height < MIN_USER_HEIGHT) {
    const minFeet = Math.floor(MIN_USER_HEIGHT / FEET_VALUE);
    const minInches = parseFloat(
      ((MIN_USER_HEIGHT - minFeet * FEET_VALUE) / INCHE_VALUE).toFixed(1),
      10
    );

    const messages = {
      metric: `Height should be greater than ${MIN_USER_HEIGHT}cm`,
      imperial: `Height should be greater than ${minFeet}ft and ${minInches}in`,
    };

    return messages[units];
  }

  if (height > MAX_USER_HEIGHT) {
    const maxFeet = Math.floor(MAX_USER_HEIGHT / FEET_VALUE);
    const maxInches = parseFloat(
      ((MAX_USER_HEIGHT - maxFeet * FEET_VALUE) / INCHE_VALUE).toFixed(1),
      10
    );

    const messages = {
      metric: `Height should be less than ${MAX_USER_HEIGHT}`,
      imperial: `Height should be less than ${maxFeet}ft and ${maxInches}in`,
    };

    return messages[units];
  }

  return true;
});

export { ValidationProvider, ValidationObserver };
