<template>
  <transition name="modal" appear>
    <div class="modal" >
      <div class="modal__wrapper">
        <div class="modal__container">
          <span
            v-if="!hideCloseButton"
            class="icon icon__close modal__close"
            @click="$emit('close')"
          />
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    hideCloseButton: { type: Boolean, default: false },
  },

  mounted() {
    document.documentElement.classList.add("out");
    window.history.pushState("forward", null, "");
    window.addEventListener("popstate", this.popStateHandler.bind(this));
  },

  beforeDestroy() {
    document.documentElement.classList.remove("out");
    window.removeEventListener("popstate", this.popStateHandler.bind(this));
  },


  methods: {
    popStateHandler() {
      this.$emit("close");
    },
  },
};
</script>
