<template>
  <div
    v-if="isShowed"
    :class="tooltipClassName"
    x-placement="top"
    v-click-outside="hide"
  >
    <div class="wrapper">
      <div class="tooltip__inner">
        <div class="pwa-tooltip">
          <h4>Install webapp on your iPhone</h4>
          <div class="pwa-tooltip__wrapper">
            <img
              class="pwa-tooltip__logo"
              src="@/assets/images/logo_32x32.png"
              alt="logo"
            />
            <div class="pwa-tooltip__body">
              Tap
              <img
                class="pwa-tooltip__icon"
                src="@/assets/images/navigation_action.png"
                alt="icon"
              />
              and then Add to Home Screen
            </div>
          </div>
          <button
            class="button button_sm button_text pwa-tooltip__button"
            @click="turnOff"
          >
            Don't show again
          </button>
        </div>
      </div>
      <div class="tooltip__arrow" />
    </div>
  </div>
</template>

<script>
import { isIphone, isInStandaloneMode } from "@/utils/system";
import { getStorage, setStorage } from "@/modules/localStorage";

export default {
  data: () => ({
    isHidden: getStorage("pwa_popup") === false,
    isMounted: false,
    timeout: null,
  }),

  computed: {
    isShowed() {
      return !isInStandaloneMode && isIphone && !this.isHidden;
    },

    tooltipClassName() {
      return {
        tooltip: true,
        tooltip_open: this.isMounted,
      };
    },
  },

  mounted() {
    this.timeout = setTimeout(() => {
      this.isMounted = true;
    }, 2000);
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    hide() {
      this.isHidden = true;
    },

    turnOff() {
      this.hide();
      setStorage({ pwa_popup: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";

.tooltip {
  position: fixed;
  bottom: 0;
  left: 50%;
  top: auto !important;
  z-index: 15;
  display: block;
  max-width: 100%;
  transform: translateX(-50%);
  transition: all $duration-fast cubic-bezier(0.75, 0, 0.3, 1.75);

  &__arrow {
    left: calc(50% - 7px);
    background-color: $color-white;
  }

  &__inner {
    background-color: $color-white;
    color: $color-dark;
  }

  &_open {
    bottom: 20px;
  }
}

.pwa-tooltip {
  display: flex;
  flex-direction: column;
  width: 240px;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__logo {
    margin-right: 0.5rem;
  }

  &__body {
    line-height: 1.5;
  }

  &__icon {
    width: 15px;
    height: 20px;
    margin-top: -5px;
    object-fit: contain;
    vertical-align: middle;
  }

  &__button {
    margin-left: auto;
    color: $color-blue;
  }
}
</style>
